<template>
  <label
    class="block w-full pb-1 font-semibold leading-normal text-gray-700 text-14px"
    :class="{ 'text-gray-700': !!error, 'text-red-600': !!error }"
  >
    <slot>{{ text }}</slot>
  </label>
</template>

<script>
export default {
  name: 'AppInputLabel',
  props: ['text', 'error'],
}
</script>

<style></style>
